<template>
  <div class="artists bgc_f5f5f5">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="init"
      v-if="artList.length > 0"
    >
      <ul class="lists">
        <li v-for="(li, i) in artList" :key="i" class="li">
          <div class="head">
            <!-- H5/art/authorDetails?id=1015&type=1 -->
            <a :href="'/H5/art/authorDetails?id=' + li.ArtistId + '&type=1'">
              <!-- <a :href="'/H5/art/artistIntroduction?id=' + li.ArtistId"> -->
              <img :src="li.HeadImg" alt="" srcset="" class="head1" />
            </a>
            <span>{{ li.ArtistName }}</span>
            <img
              v-if="li.IsSign == 1"
              src="@/assets/images/icon_qian.png"
              alt=""
              class="icon_qian"
              srcset=""
            />
          </div>
          <!-- <div class="content" v-html="li.Remark"></div> -->
          <p class="decimalPoint-2">{{ li.Remark }}</p>
          <div class="imgs">
            <ul>
              <li v-for="(lia, i) in li.ArtWorks" :key="i">
                <img
                  :src="lia.CoverImg"
                  alt=""
                  @click="artClick(lia.Id)"
                  srcset=""
                />
              </li>
              <!-- <li class="more">查看更多>></li> -->
            </ul>
            <div class="moreOne" v-if="li.ArtWorks.length > 3">
              <a
                :href="
                  '/H5/art/authorDetails?id=' +
                  li.ArtistId +
                  '&type=1&typesIndex=1'
                "
              >
                <van-icon name="arrow" color="#fff" />
              </a>
            </div>
          </div>
        </li>
      </ul>
    </van-list>
    <div v-if="artList.length <= 0">
      <no-data></no-data>
    </div>
  </div>
</template>

<script>
import { queryNewestArtist } from "@/api/art";
import noData from "@/components/noData";

export default {
  name: "index",
  components: { noData },
  computed: {},
  data () {
    return {
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      artList: [],
    };
  },
  created () { },
  mounted () {
    this.init();
  },
  methods: {
    artClick (id) {
      // let url = "/shop/goods/detail/" + id
      let url = "/H5/art/artistsDetails?id=" + id + '&artUserId=' + localStorage.getItem("artUserId");
      this.$router.push({ path: url });
    },
    async init () {
      let parm = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      const res = await queryNewestArtist(parm);
      if (res.success == true) {
        res.response.data.forEach(element => {
          if (!element.Remark) return
          element.Remark = element.Remark.replace(/<[^>]+>/g, '')
        });
        this.artList = this.artList.concat(res.response.data);
        this.pageIndex++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.artList.length >= res.response.dataCount) {
          this.finished = true;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.artists {
  .lists {
    .li {
      background-color: #fff;
      margin-bottom: 10px;
      padding: 20px 15px;
      // height: 230px;
      position: relative;
      .head {
        margin-bottom: 10px;
        .head1 {
          height: 36px;
          width: 36px;
          border-radius: 50px;
          margin-right: 10px;
        }
        span {
          font-size: 15px;
          font-weight: bold;
          line-height: 18px;
          color: #333333;
        }
        .icon_qian {
          height: 14px;
          width: 14px;
          margin-left: 4px;
        }
      }
      p {
        line-height: 22px;
        color: #6b666b;
        font-size: 14px;
      }
      .imgs {
        position: relative;
        ul {
          margin: 15px 0 0;
          display: -webkit-box;
          overflow: auto;
          -webkit-overflow-scrolling: touch;
          &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
          }
          li {
            height: 105px;
            width: 105px;
            margin-right: 15px;
            img {
              display: inline-block;
              height: 100%;
              width: 100%;
            }
          }
        }
        .moreOne {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 42px;
          right: -10px;
          width: 22px;
          height: 22px;
          border-radius: 50px;
          background-color: rgba(0, 0, 0, 0.3);
        }
        .more {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #999;
        }
      }
    }
  }
}
</style>
